import { request, serviceName } from './base-config';

// 隐患占比分析
export function getSpotInspectCount(days) {
  return request(`${serviceName}/screen/inspect/count`, {
    method: 'GET',
    body: days,
  });
}
//隐患-巡检数据
export function getSpotDangerInspectionCount(days) {
  return request(`${serviceName}/screen/inspect/dangerInspection/count`, {
    method: 'GET',
    body: days,
  });
}

//隐患趋势
export function getSpotInspectionTrendCount(days) {
  return request(`${serviceName}/screen/inspect/danger/level/trend`, {
    method: 'GET',
    body: days,
  });
}

//巡检问题分析
export function getSpotInspectionStateCount(days) {
  return request(`${serviceName}/screen/inspect/inspection/state/count`, {
    method: 'GET',
    body: days,
  });
}
//隐患问题分析
export function getSpotDangerStateCount(days) {
  return request(`${serviceName}/screen/inspect/danger/state/count`, {
    method: 'GET',
    body: days,
  });
}
//巡检-检查趋势
export function getInspectTrendCount(days) {
  return request(`${serviceName}/screen/inspect/trend`, {
    method: 'GET',
    body: days,
  });
}

//三类-安全检查数据
export function getInspectTypeStateCount(days) {
  return request(`${serviceName}/screen/inspect/type/state/count`, {
    method: 'GET',
    body: days,
  });
}

//安全检查记录
export function getInspectSafeInspectionCount(days) {
  return request(`${serviceName}/screen/inspect/safe/inspection`, {
    method: 'GET',
    body: days,
  });
}

//隐患记录
export function getInspectDangerRecords(days) {
  return request(`${serviceName}/screen/inspect/danger`, {
    method: 'GET',
    body: days,
  });
}

//巡检记录
export function getInspectInspectionRecords(days) {
  return request(`${serviceName}/screen/inspect/inspection`, {
    method: 'GET',
    body: days,
  });
}

//安全检查记录
export function getInspectSafeRecords(days) {
  return request(`${serviceName}/screen/inspect/safe/inspection`, {
    method: 'GET',
    body: days,
  });
}
//企业检查记录
export function getInspectEnterpriseRecords(days) {
  return request(`${serviceName}/screen/inspect/enterprise/inspection`, {
    method: 'GET',
    body: days,
  });
}

//项目检查记录
export function getInspectProjectRecords(days) {
  return request(`${serviceName}/screen/inspect/project/inspection`, {
    method: 'GET',
    body: days,
  });
}

//监督检查记录
export function getInspectSuperviseRecords(days) {
  return request(`${serviceName}/screen/inspect/supervise/inspection`, {
    method: 'GET',
    body: days,
  });
}

//隐患类型分析

export function getInspectTypeCount(days) {
  return request(`${serviceName}/screen/inspect/type/count`, {
    method: 'GET',
    body: days,
  });
}
