<template>
  <div class="label-content">
    <ul class="itemLists">
      <li
        class="item"
        v-for="item in dataLists"
        :key="item.name"
        @click="showDetailDialog(item)"
      >
        <h3>{{ item.count }}</h3>
        <p>{{ item.name }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class LaborStatus extends Vue {
  @Prop({ TYPE: Object, default: () => {} }) data;
  get dataLists() {
    return [
      {
        name: '累计隐患数',
        count: this.data.dangerNum,
        days: '',
        type: 'danger',
      },
      {
        name: '本月隐患数',
        count: this.data.monthDangerNum,
        days: 'CURRENT_MONTH',
        type: 'danger',
      },
      {
        name: '今日隐患数',
        count: this.data.todayDangerNum,
        days: 'CURRENT_DAY',
        type: 'danger',
      },
      {
        name: '累计巡检数',
        count: this.data.inspectionNum,
        days: '',
        type: 'inspect',
      },
      {
        name: '本月巡检数',
        count: this.data.monthInspectionNum,
        days: 'CURRENT_MONTH',
        ype: 'inspect',
      },
      {
        name: '今日巡检数',
        count: this.data.todayInspectionNum,
        days: 'CURRENT_DAY',
        ype: 'inspect',
      },
    ];
  }

  showDetailDialog(item) {
    this.$emit('handleDangerAndInspectDialog', item);
  }
}
</script>

<style lang="less" scoped>
.label-content {
  padding: 0.06rem 0.6rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  .itemLists {
    list-style: none;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0;
    align-items: center;
    width: 4.3rem;

    .item {
      width: 0.97rem;
      height: 0.88rem;
      margin: 0.1rem 0 0 0.14rem;
      cursor: pointer;
      background: linear-gradient(
        270deg,
        rgba(76, 175, 255, 0) 0%,
        rgba(76, 175, 255, 0.08) 52%,
        rgba(76, 175, 255, 0) 100%
      );
      display: flex;
      align-items: center;
      flex-direction: column;

      h3 {
        margin-top: 0.1rem;
        font-size: 0.24rem;
        font-family: 'Microsoft YaHei';
        font-weight: 700;
        color: #fcffff;
        text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
        background: url('~@/assets/images/theme/labor-icon.png') no-repeat
          center bottom;
        width: 0.7rem;
        background-size: 100%;
        padding-bottom: 0.08rem;
      }
      p {
        margin: 0;
        font-size: 0.14rem;
        font-family: D-DIN-Bold;
        font-weight: 700;
        color: #d8e1f3;
      }
    }
  }
}
</style>
