<template>
  <div>
    <a-form-model layout="inline" :model="formInline">
      <a-form-model-item>
        <a-input
          v-model="formInline.keyword"
          :placeholder="$t(placeholder)"
          @input="postHanlder"
          allowClear
        >
          <template #prefix>
            <a-icon type="search" />
          </template>
        </a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SpotForm extends Vue {
  @Prop({ type: String, default: '搜索隐患单号' }) placeholder;
  formInline = {
    keyword: '',
  };
  postHanlder() {
    // console.log(this.formInline);
    this.$emit('handleSearch', this.formInline);
  }
}
</script>
<style lang="less" scoped></style>
