<template>
  <div :class="$style.wrapper">
    <div :class="$style.card">
      <LayoutCard title="隐患随手拍问题分析">
        <!-- {{ dialogType }} --{{ sixDialogData }} -->
        <template #action>
          <LayoutSelect
            :options="dateList"
            defaultValue="CURRENT_DAY"
            @change="val => handleSelect(val, 'safeCatchPie')"
          ></LayoutSelect>
        </template>
        <PieChart
          :chartData="safeCatchData"
          @click="showCatchDetailDialog"
          :labelOption="dangerStateTotalLabelOption"
        />
      </LayoutCard>
    </div>
    <div :class="$style.card">
      <LayoutCard title="隐患/巡检数据">
        <ProblemAndLookupData
          :data="spotDangerInspectionCount"
          @handleDangerAndInspectDialog="handleDangerDataDialog"
        />
      </LayoutCard>
    </div>
    <div :class="$style.card">
      <LayoutCard title="隐患类型分析">
        <template #action>
          <LayoutSelect
            :options="dateList"
            defaultValue="CURRENT_DAY"
            @change="val => handleSelect(val, 'dangerTypePie')"
          ></LayoutSelect>
        </template>
        <div class="h-full overflow-hidden" style="padding: 0.16rem">
          <!-- <LineChart
            chart-width="100%"
            chart-height="100%"
            isSmooth
            symbol="round"
            chartRight="2%"
            yName="单位：次"
            :chart-data="trendProblemLineChartData"
            :show-legend="true"
          /> -->
          <PieChart
            :chartData="dangerTypeData"
            :labelOption="dangerTypeTotalLabelOption"
            v-if="dangerTypePieTotal"
          />
          <div v-else style="height:90%;width: 1rem;margin: 0 auto;">
            <NoImg />
          </div>
        </div>
      </LayoutCard>
    </div>
    <div :class="$style.card">
      <LayoutCard title="巡检问题分析">
        <template #action>
          <LayoutSelect
            :options="dateList"
            defaultValue="CURRENT_DAY"
            @change="val => handleSelect(val, 'inspectStatus')"
          ></LayoutSelect>
        </template>
        <PieChart
          :chartData="inspectStatusDatas"
          @click="showLookupDetailDialog"
          :labelOption="inspectStatusTotalLabelOption"
        />
      </LayoutCard>
    </div>
    <div :class="$style.card">
      <LayoutCard title="隐患占比分析">
        <PieChart
          :chartData="problemPercentData"
          :labelOption="problemPercentLabelOption"
        />
      </LayoutCard>
    </div>
    <div :class="$style.card">
      <LayoutCard title="巡检/检查趋势">
        <template #action>
          <LayoutSelect
            :options="dateList.slice(1)"
            defaultValue="SEVEN_DAY"
            @change="val => handleSelect(val, 'inspectTrend')"
          ></LayoutSelect>
        </template>
        <div class="h-full overflow-hidden" style="padding: 0.16rem">
          <LineChart
            chart-width="100%"
            chart-height="100%"
            isSmooth
            symbol="round"
            chartRight="2%"
            yName="单位：次"
            :chart-data="trendInspectLineChartData"
            :show-legend="true"
          />
        </div>
      </LayoutCard>
    </div>
    <div :class="$style.bottom">
      <div :class="$style.card_2">
        <LayoutCard title="三类检查数据">
          <template #action>
            <div :class="$style.actionBar">
              <LayoutTabs
                :class="$style.btnSwitch"
                default-value="SAFE_INSPECTION"
                :options="[
                  { key: 'SAFE_INSPECTION', text: '其他检查' },
                  { key: 'ENTERPRISE_INSPECTION', text: '企业检查' },
                  { key: 'PROJECT_INSPECTION', text: '项目检查' },
                  { key: 'SUPERVISE_INSPECTION', text: '监督检查' },
                ]"
                @change="changeThreeTypesSwitchBtn"
              />
              <LayoutSelect
                :options="dateList"
                defaultValue="CURRENT_DAY"
                @change="val => handleSelect(val, 'stateType')"
              ></LayoutSelect>
            </div>
          </template>
          <PieChart
            :chartData="stateTypeTotalData"
            @click="showThreeProblemDetailDialog"
            :labelOption="stateTypeTotalLabelOption"
          />
        </LayoutCard>
      </div>
      <div :class="$style.card_3" class="h-full overflow-hidden">
        <LayoutCard title="隐患/巡检/检查记录">
          <template #action>
            <LayoutTabs
              :class="$style.btnSwitch3"
              default-value="danger"
              :options="[
                { key: 'danger', text: '隐患' },
                { key: 'inspect', text: '巡检' },
                { key: 'safeInspect', text: '其他检查' },
                { key: 'compInspect', text: '企业检查' },
                { key: 'projectInspect', text: '项目检查' },
                { key: 'watchInspect', text: '监督检查' },
              ]"
              @change="change6TypesSwitchBtn"
            />
          </template>
          <div class="h-full overflow-hidden">
            <ScrollTable
              v-if="scrollTbaleData.length"
              :columns="recordsColmuns"
              :data="scrollTbaleData"
              @cellClick="sixCellClick"
              :infiniteScroll="false"
              :key="sixClickType"
            ></ScrollTable>
            <div v-else style="height:90%;width: 1rem;margin: 0 auto;">
              <NoImg />
            </div>
          </div>
        </LayoutCard>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutTabs, LayoutCard, LayoutSelect } from '../../layout';
import PieChart from '../../e-charts/pie-chart.vue';
import LineChart from '@/views/screen/e-charts/line-chart';
import ProblemAndLookupData from './components/problemAndLookupData.vue';
import { PublicSize } from '@/views/green-construction/components/utils';
import PeopleManageMentDialog from '../people-management/components/peopleManageMentDialog';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import { createModal } from '@triascloud/x-components';
import { getPieChartTotal, getPieChartDesc } from '../../utils';
import NoImg from '../people-management/components/noImg.vue';
import {
  getSpotInspectCount,
  getSpotDangerInspectionCount,
  // getSpotInspectionTrendCount,
  getSpotInspectionStateCount,
  getSpotDangerStateCount,
  getInspectTrendCount,
  getInspectTypeStateCount,
  getInspectDangerRecords,
  getInspectInspectionRecords,
  getInspectSafeRecords,
  getInspectEnterpriseRecords,
  getInspectProjectRecords,
  getInspectSuperviseRecords,
  getInspectTypeCount,
} from '@/services/screen/jiangsu/spot-inspect';
// import { UploadService } from '@triascloud/services';
import { debounce } from 'lodash';
import SpotForm from './components/spotForm';
import {
  dangerRecordsColumns,
  inspectRecordsColumns,
  inspectSafeRecordsColumns,
  inspectEnterpriseRecordsData,
  inspectProjectRecordsData,
  inspectSuperviseRecordsData,
} from '../config';
@Component({
  components: {
    LayoutCard,
    LayoutTabs,
    LayoutSelect,
    PieChart,
    LineChart,
    ProblemAndLookupData,
    PeopleManageMentDialog,
    ScrollTable,
    NoImg,
    SpotForm,
  },
})
export default class SpotCheckingIndex extends Vue {
  created() {
    this.init();
  }
  init() {
    this.getSpotInspectCountData();
    this.getSpotDangerInspectionCountData();
    // this.getDangerTypePieData();
    this.getSpotInspectionStatusData();
    this.getSpotDangerStateData();
    this.getInspectTrendData();
    this.getInspectTypeStateData();
    this.getInspectDangerRecordsData();
    this.getInspectTypeCountData();
  }
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  loading = false;
  dateList = [
    {
      label: this.$t('screen.currentDay'),
      value: 'CURRENT_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];

  //隐患巡检数据
  params = { projectId: this.projectId };
  spotDangerInspectionCount = {};
  getSpotDangerInspectionCountData() {
    getSpotDangerInspectionCount(this.params).then(res => {
      // window.console.log(res, 'getSpotDangerInspectionCount');
      this.spotDangerInspectionCount = res;
    });
  }

  dangerAndInspectDialogData = [];
  //弹窗类型，用于区别弹窗里面的查看记录详情里面的字段
  dialogType = 'danger';
  placeholder = '搜索隐患单号';
  pagination = {
    pageSize: 10,
    current: 1,
  };
  handleDangerDataDialog(data) {
    this.dangerCurrent = 1;
    this.dangerSize = 10;
    let columns = this.dangerColumns;
    let title = '';
    this.pagination = {
      current: 1,
      pageSize: 10,
    };
    this.keyword = '';
    if (data.type === 'danger') {
      this.dangerDays = data.days;
      columns = this.dangerColumns;
      this.dangerState = '';
      this.getInspectDangerRecordsDialog();
      title = '隐患数据';
      this.dialogType = 'danger';
      this.placeholder = '搜索隐患单号';
    } else {
      this.inspectDays = data.days;
      title = '巡检数据';
      columns = this.inspectColumns;
      this.inspectState = '';
      this.getInspectInspectionRecordDialogData();
      this.dialogType = 'inspect';
      this.placeholder = '搜索巡检单号';
    }

    createModal(
      () => (
        <PeopleManageMentDialog
          columns={columns}
          showForm={false}
          showAction
          isLook
          total={this.total}
          data={this.dangerAndInspectDialogData}
          onChangePagination={this.changePagination}
          dialogType={this.dialogType}
          title={`${title}单`}
          loading={this.loading}
          scopedSlots={{
            header: () => (
              <SpotForm
                placeholder={this.placeholder}
                onHandleSearch={debounce(this.handleSpotSearch, 1000)}
              />
            ),
          }}
        />
      ),
      {
        title,
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }

  handleSpotSearch(data) {
    // window.console.log('handleSpotSearch', data);
    this.keyword = data.keyword;
    this.pagination = {
      pageSize: 10,
      current: 1,
    };
    if (this.dialogType === 'danger') {
      this.dangerCurrent = 1;
      this.dangerSize = 10;
      this.getInspectDangerRecordsDialog();
    } else if (this.dialogType === 'inspect') {
      this.inspectCurrent = 1;
      this.getInspectInspectionRecordDialogData();
    } else {
      this.paramsData.current = 1;
      this.paramsData.keyword = data.keyword;
      // window.console.log(this.paramsData, 'paramsData');
      this.getInspectSafeRecordsDialogData();
    }
  }

  //隐患类型分析
  dangerTypePieDays = 'CURRENT_DAY';
  dangerTypeData = [];
  dangerTypePieTotal = 0;
  getInspectTypeCountData() {
    const data = {
      days: this.dangerTypePieDays,
      projectId: this.projectId,
    };
    getInspectTypeCount(data).then(res => {
      // window.console.log(res, 'getInspectTypeCount');
      //处理
      const [ret, total] = getPieChartTotal(res);
      // window.console.log(ret, 'ret');
      // window.console.log(total, 'total');
      this.dangerTypeData = ret;
      this.dangerTypePieTotal = total;
    });
  }

  get dangerTypeTotalLabelOption() {
    return getPieChartDesc(this.dangerTypePieTotal);
  }

  //巡检问题分析
  inspectStatusDays = 'CURRENT_DAY';
  inspectStatusDatas = [];
  inspectStatusTotal = 0;
  getSpotInspectionStatusData() {
    const data = {
      days: this.inspectStatusDays,
      projectId: this.projectId,
    };
    getSpotInspectionStateCount(data).then(res => {
      //// window.console.log(res, 'getSpotInspectionStateCount');
      const [ret, total] = getPieChartTotal(res);
      this.inspectStatusDatas = ret;
      this.inspectStatusTotal = total;
    });
  }

  get inspectStatusTotalLabelOption() {
    return getPieChartDesc(this.inspectStatusTotal);
  }

  //隐患占比分析
  problemPercentData = [];
  safeCatchPieDays = 'CURRENT_DAY';
  problemPercenTotal = 0;
  getSpotInspectCountData() {
    getSpotInspectCount({
      days: this.safeCatchPieDays,
      projectId: this.projectId,
    }).then(res => {
      // window.console.log(res, 'getSpotInspectCount');
      const [ret, total] = getPieChartTotal(res);
      this.problemPercentData = ret;
      this.problemPercenTotal = total;
    });
  }
  get problemPercentLabelOption() {
    return getPieChartDesc(this.problemPercenTotal);
  }

  //隐患问题分析
  safeCatchData = [];
  dangerStateDays = 'CURRENT_DAY';
  dangerStateTotal = 0;
  getSpotDangerStateData() {
    const data = {
      days: this.dangerStateDays,
      projectId: this.projectId,
    };
    getSpotDangerStateCount(data).then(res => {
      // window.console.log(res, 'getSpotDangerStateCount');
      const [ret, total] = getPieChartTotal(res);
      this.safeCatchData = ret;
      this.dangerStateTotal = total;
    });
  }

  get dangerStateTotalLabelOption() {
    return getPieChartDesc(this.dangerStateTotal);
  }

  //巡检-检查趋势
  inspectTrendDays = 'SEVEN_DAY';
  trendInspectLineChartData = [];
  getInspectTrendData() {
    const data = {
      days: this.inspectTrendDays,
      projectId: this.projectId,
    };
    getInspectTrendCount(data).then(res => {
      // window.console.log(res, 'getInspectTrendCount');
      //处理
      const data = [];
      res &&
        res.dataList.forEach(ele => {
          data.push({
            name: ele.tag,
            data: ele.values,
            unit: '次',
          });
        });
      this.trendInspectLineChartData = [
        {
          time: res.times,
          data,
        },
      ];
    });
  }

  //三类-安全检查数据
  stateType = 'SAFE_INSPECTION';
  stateTypeDays = 'CURRENT_DAY';
  stateTypeTotal = 0;
  stateTypeTotalData = [];
  getInspectTypeStateData() {
    const data = {
      days: this.stateTypeDays,
      type: this.stateType,
      projectId: this.projectId,
    };
    getInspectTypeStateCount(data).then(res => {
      //// window.console.log(res, 'getInspectTypeStateCount');
      const [ret, total] = getPieChartTotal(res);
      this.stateTypeTotalData = ret;
      this.stateTypeTotal = total;
    });
  }
  get stateTypeTotalLabelOption() {
    return getPieChartDesc(this.stateTypeTotal);
  }

  handleSelect(val, type) {
    // console.log('value', val);
    if (type === 'safeCatchPie') {
      this.safeCatchPieDays = val;
      this.dangerStateDays = val;
      this.getSpotDangerStateData();
    } else if (type === 'dangerTypePie') {
      this.dangerTypePieDays = val;
      // this.getDangerTypePieData();
      this.getInspectTypeCountData();
    } else if (type === 'inspectStatus') {
      this.inspectStatusDays = val;
      this.getSpotInspectionStatusData();
    } else if (type === 'inspectTrend') {
      this.inspectTrendDays = val;
      this.getInspectTrendData();
    } else if (type === 'stateType') {
      this.stateTypeDays = val;
      this.getInspectTypeStateData();
    }
  }

  problemAnalysisData = [
    { value: 326, name: '待整改' },
    { value: 735, name: '已整改' },
    { value: 735, name: '待审批' },
  ];

  recordsColmuns = [];
  sixClickType = 'danger';
  change6TypesSwitchBtn(value) {
    this.sixClickType = value;
    this.dangerSize = 5;
    if (value === 'danger') {
      this.recordsColmuns = this.dangerRecordsColumns;
      this.getInspectDangerRecordsData();
    } else if (value === 'inspect') {
      this.recordsColmuns = this.inspectRecordsColumns;
      this.getInspectInspectionRecordData();
    } else if (value === 'safeInspect') {
      this.recordsColmuns = this.inspectSafeRecordsColumns;
      this.getInspectSafeRecordsData();
    } else if (value === 'compInspect') {
      this.recordsColmuns = this.inspectEnterpriseRecordsData;
      this.getInspectEnterpriseRecordsData();
    } else if (value === 'projectInspect') {
      this.recordsColmuns = this.inspectProjectRecordsData;
      this.getInspectProjectRecordsData();
    } else if (value === 'watchInspect') {
      this.recordsColmuns = this.inspectSuperviseRecordsData;
      this.getInspectSuperviseRecordsData();
    }
  }
  scrollTbaleData = [];
  sixCellClick(data) {
    if (!data.record) return false;
    // window.console.log('sixCellClick', data);
    this.paramsSixDialogData.current = 1;
    this.paramsSixDialogData.size = 10;
    this.paramsSixDialogData.keyword = '';
    this.dangerSize = 10;
    let columns = this.dangerColumns;
    this.pagination = {
      pageSize: 10,
      current: 1,
    };
    let title = '隐患数据';
    switch (this.sixClickType) {
      case 'danger':
        title = '隐患数据';
        columns = this.dangerColumns;
        this.dialogType = 'danger';
        this.placeholder = '搜索隐患单号';
        break;
      case 'inspect':
        columns = this.inspectColumns;
        this.dialogType = 'inspect';
        title = '巡检数据';
        this.placeholder = '搜索巡检单号';
        break;
      case 'safeInspect':
        columns = this.threeProblemSafeColumns;
        this.dialogType = 'safe';
        title = '其他检查数据';
        this.placeholder = '搜索检查单号';
        break;
      case 'compInspect':
        columns = this.threeProblemCompanyColumns;
        title = '企业检查数据';
        this.dialogType = 'company';
        this.placeholder = '搜索检查单号';
        break;
      case 'projectInspect':
        columns = this.threeProblemProjectColumns;
        title = '项目检查数据';
        this.dialogType = 'project';
        this.placeholder = '搜索检查单号';
        break;
      case 'watchInspect':
        columns = this.threeProblemWacthColumns;
        title = '监督检查数据';
        this.dialogType = 'watch';
        this.placeholder = '搜索检查单号';
        break;
      default:
        break;
    }
    this.getSixInspectRecordDialogData();
    createModal(
      () => (
        <PeopleManageMentDialog
          columns={columns}
          showForm={false}
          showAction
          isLook
          data={this.sixDialogData}
          total={this.total}
          onChangePagination={this.changeSixDialogPagination}
          dialogType={this.dialogType}
          title={`${title}单`}
          loading={this.loading}
          scopedSlots={{
            header: () => (
              <SpotForm
                placeholder={this.placeholder}
                onHandleSearch={debounce(this.handleSpotSixSearch, 1000)}
              />
            ),
          }}
        />
      ),
      {
        title: this.$t(title),
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }
  handleSpotSixSearch(data) {
    // window.console.log('handleSpotSixSearch', data);
    this.paramsSixDialogData.keyword = data.keyword;
    this.paramsSixDialogData.current = 1;
    this.pagination = {
      pageSize: 10,
      current: 1,
    };
    this.getSixInspectRecordDialogData();
  }
  changeSixDialogPagination(data) {
    this.paramsSixDialogData.current = data.current;
    this.paramsSixDialogData.size = data.pageSize;
    this.pagination = {
      pageSize: data.pageSize,
      current: data.current,
    };
    this.getSixInspectRecordDialogData();
  }
  paramsSixDialogData = {
    current: 1,
    days: '',
    projectId: this.projectId,
    size: 10,
    state: '',
    keyword: this.keyword,
  };
  sixDialogData = [];
  async getSixInspectRecordDialogData() {
    let res = null;
    this.loading = true;
    switch (this.sixClickType) {
      case 'danger':
        res = await getInspectDangerRecords(this.paramsSixDialogData);
        this.sixDialogData = res.records;
        this.total = res.total;
        break;
      case 'inspect':
        res = await getInspectInspectionRecords(this.paramsSixDialogData);
        this.sixDialogData = res.records;
        this.total = res.total;
        break;
      case 'safeInspect':
        res = await getInspectSafeRecords(this.paramsSixDialogData);
        this.sixDialogData = res.records;
        this.total = res.total;
        break;
      case 'compInspect':
        res = await getInspectEnterpriseRecords(this.paramsSixDialogData);
        this.sixDialogData = res.records;
        this.total = res.total;
        break;
      case 'projectInspect':
        res = await getInspectProjectRecords(this.paramsSixDialogData);
        this.sixDialogData = res.records;
        this.total = res.total;
        break;
      case 'watchInspect':
        res = await getInspectSuperviseRecords(this.paramsSixDialogData);
        this.sixDialogData = res.records;
        this.total = res.total;
        break;
      default:
        this.sixDialogData = [];
        this.total = 0;
        break;
    }
    this.loading = false;
  }

  //隐患记录
  get dangerRecordsColumns() {
    return dangerRecordsColumns;
  }

  dangerSize = 5;
  dangerState = '';
  dangerCurrent = 1;
  data = {
    current: 1,
    size: this.dangerSize,
    projectId: this.projectId,
  };
  async getInspectDangerRecordsData() {
    this.recordsColmuns = this.dangerRecordsColumns;
    const { records } = await getInspectDangerRecords(this.data);
    this.scrollTbaleData = records;
  }
  dangerDays = '';
  dangerProblemDialogData = [];
  total = 0;
  keyword = '';
  async getInspectDangerRecordsDialog() {
    const data = {
      current: this.dangerCurrent,
      size: this.dangerSize,
      projectId: this.projectId,
      days: this.dangerDays,
      state: this.dangerState,
      keyword: this.keyword,
    };
    this.loading = true;
    const { records, total } = await getInspectDangerRecords(data);
    this.dangerProblemDialogData = records;
    this.total = total;
    this.dangerAndInspectDialogData = records;
    this.loading = false;
  }
  //巡检记录
  get inspectRecordsColumns() {
    return inspectRecordsColumns;
  }
  async getInspectInspectionRecordData() {
    const { records } = await getInspectInspectionRecords(this.data);
    this.scrollTbaleData = records;
  }
  inspectCurrent = 1;
  inspectSize = 10;
  inspectDays = '';
  inspectState = '';
  inspectRecordsDialogData = [];
  async getInspectInspectionRecordDialogData() {
    const data = {
      current: this.inspectCurrent,
      size: this.inspectSize,
      projectId: this.projectId,
      days: this.inspectDays,
      state: this.inspectState,
      keyword: this.keyword,
    };
    this.loading = true;
    const { records, total } = await getInspectInspectionRecords(data);
    this.inspectRecordsDialogData = records;
    this.total = total;
    this.dangerAndInspectDialogData = records;
    this.loading = false;
  }
  //安全检查
  get inspectSafeRecordsColumns() {
    return inspectSafeRecordsColumns;
  }
  async getInspectSafeRecordsData() {
    const { records } = await getInspectSafeRecords(this.data);
    this.scrollTbaleData = records;
  }
  //企业检查
  get inspectEnterpriseRecordsData() {
    return inspectEnterpriseRecordsData;
  }
  async getInspectEnterpriseRecordsData() {
    const { records } = await getInspectEnterpriseRecords(this.data);
    this.scrollTbaleData = records;
  }
  //项目检查记录
  get inspectProjectRecordsData() {
    return inspectProjectRecordsData;
  }
  async getInspectProjectRecordsData() {
    const { records } = await getInspectProjectRecords(this.data);
    this.scrollTbaleData = records;
  }
  //监督检查记录
  get inspectSuperviseRecordsData() {
    return inspectSuperviseRecordsData;
  }
  async getInspectSuperviseRecordsData() {
    const { records } = await getInspectSuperviseRecords(this.data);
    this.scrollTbaleData = records;
  }

  dangerColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '隐患单号',
      dataIndex: 'number',
      key: 'number',
      width: 160,
    },
    // {
    //   title: '项目',
    //   dataIndex: 'projectName',
    //   key: 'projectName',
    //   width: 200,
    // },
    {
      title: '上报人员',
      dataIndex: 'fullName',
      key: 'fullName',
      ellipsis: true,
      width: 140,
    },
    // {
    //   title: '手机号',
    //   dataIndex: 'phone',
    //   key: 'phone',
    //   ellipsis: true,
    //   width: 140,
    // },
    // {
    //   title: '隐患位置',
    //   dataIndex: 'dangerLocation',
    //   key: 'dangerLocation',
    //   ellipsis: true,
    //   width: 200,
    // },
    {
      title: '隐患描述',
      dataIndex: 'dangerContent',
      key: 'dangerContent',
      ellipsis: true,
      width: 200,
    },
    {
      title: '状态',
      dataIndex: 'dangerState',
      key: 'dangerState',
      ellipsis: true,
      width: 100,
    },
    {
      title: '检查时间',
      dataIndex: 'dangerTime',
      key: 'dangerTime',
      ellipsis: true,
      width: 200,
      customRender: text => this.dayjs(text).format('YYYY-MM-DD'),
    },
    {
      title: '隐患照片',
      dataIndex: 'dangerPictures',
      width: 200,
      scopedSlots: { customRender: 'dImages' },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 60,
      scopedSlots: { customRender: 'action' },
    },
    {
      title: '',
      dataIndex: 'erCode',
      key: 'erCode',
      width: 60,
      scopedSlots: { customRender: 'erCode' },
    },
  ];
  changePagination(data) {
    // console.log(data, '9999');
    this.dangerCurrent = data.current;
    this.dangerSize = data.pageSize;
    this.pagination = {
      current: data.current,
      pageSize: data.pageSize,
    };
    this.getInspectDangerRecordsDialog();
  }

  //隐患数据弹窗
  showCatchDetailDialog(data) {
    if (!data.name) return;
    this.dangerState = data.name;
    this.dialogType = 'danger';
    this.dangerSize = 10;
    this.dangerCurrent = 1;
    this.dangerDays = this.dangerStateDays;
    this.keyword = '';
    this.pagination = {
      current: 1,
      pageSize: 10,
    };
    this.getInspectDangerRecordsDialog();

    createModal(
      () => (
        <PeopleManageMentDialog
          columns={this.dangerColumns}
          showForm={false}
          showAction
          isLook
          total={this.total}
          data={this.dangerProblemDialogData}
          onChangePagination={this.changePagination}
          title={'隐患数据单'}
          loading={this.loading}
          scopedSlots={{
            header: () => (
              <SpotForm
                onHandleSearch={debounce(this.handleSpotSearch, 1000)}
              />
            ),
          }}
        />
      ),
      {
        title: '隐患数据',
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }
  //巡检数据弹窗
  inspectColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '巡检编号',
      dataIndex: 'number',
      key: 'number',
      width: 160,
    },
    // {
    //   title: '项目',
    //   dataIndex: 'projectName',
    //   key: 'projectName',
    //   width: 200,
    // },
    {
      title: '巡检人',
      dataIndex: 'fullName',
      key: 'fullName',
      ellipsis: true,
      width: 100,
    },
    // {
    //   title: '检查标准',
    //   dataIndex: 'inspectionStandard',
    //   key: 'inspectionStandard',
    //   ellipsis: true,
    //   width: 140,
    // },
    {
      title: '巡检点',
      dataIndex: 'inspectionLocation',
      key: 'inspectionLocation',
      ellipsis: true,
      width: 200,
    },
    // {
    //   title: '巡检内容',
    //   dataIndex: 'inspectionContent',
    //   key: 'inspectionContent',
    //   ellipsis: true,
    //   width: 200,
    // },
    {
      title: '巡检结果',
      dataIndex: 'inspectionResult',
      key: 'inspectionResult',
      ellipsis: true,
      width: 100,
    },
    {
      title: '巡检照片',
      dataIndex: 'inspectionPictures',
      key: 'inspectionPictures',
      ellipsis: true,
      width: 200,
      scopedSlots: { customRender: 'dImages' },
    },
    {
      title: '状态',
      dataIndex: 'inspectionState',
      key: 'inspectionState',
      width: 90,
    },
    {
      title: '巡检时间',
      dataIndex: 'inspectionTime',
      key: 'inspectionTime',
      ellipsis: true,
      width: 160,
      customRender: text => this.dayjs(text).format('YYYY-MM-DD'),
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 60,
      scopedSlots: { customRender: 'action' },
    },
    {
      title: '',
      dataIndex: 'erCode',
      key: 'erCode',
      width: 50,
      scopedSlots: { customRender: 'erCode' },
    },
  ];
  changeInspectPagination(data) {
    //window.console.log(data, 'changeInspectPagination');
    this.inspectCurrent = data.current;
    this.inspectSize = data.pageSize;
    this.pagination = {
      current: data.current,
      pageSize: data.pageSize,
    };
    this.getInspectInspectionRecordDialogData();
  }
  showLookupDetailDialog(data) {
    if (!data.name) return;
    this.inspectState = data.name;
    this.inspectCurrent = 1;
    this.inspectSize = 10;
    this.inspectDays = this.inspectStatusDays;
    this.dialogType = 'inspect';
    this.placeholder = '搜索巡检单号';
    this.keyword = '';
    this.pagination = {
      current: 1,
      pageSize: 10,
    };
    this.getInspectInspectionRecordDialogData();
    createModal(
      () => (
        <PeopleManageMentDialog
          columns={this.inspectColumns}
          showForm={false}
          showAction
          isLook
          total={this.total}
          dialogType={this.dialogType}
          data={this.inspectRecordsDialogData}
          onChangePagination={this.changeInspectPagination}
          title={'巡检数据单'}
          loading={this.loading}
          scopedSlots={{
            header: () => (
              <SpotForm
                placeholder={this.placeholder}
                onHandleSearch={debounce(this.handleSpotSearch, 1000)}
              />
            ),
          }}
        />
      ),
      {
        title: '巡检数据',
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }
  threeProblemSafeColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '检查编号',
      dataIndex: 'number',
      key: 'number',
      width: 180,
    },
    // {
    //   title: '项目',
    //   dataIndex: 'projectName',
    //   key: 'projectName',
    //   width: 200,
    // },
    {
      title: '检查人',
      dataIndex: 'fullName',
      key: 'fullName',
      ellipsis: true,
      width: 140,
    },
    {
      title: '检查部位',
      dataIndex: 'inspectionLocation',
      key: 'inspectionLocation',
      ellipsis: true,
      width: 200,
    },
    // {
    //   title: '检查内容',
    //   dataIndex: 'inspectionContent',
    //   key: 'inspectionContent',
    //   ellipsis: true,
    //   width: 200,
    // },
    {
      title: '检查照片',
      dataIndex: 'inspectionPictures',
      key: 'inspectionPictures',
      ellipsis: true,
      scopedSlots: { customRender: 'dImages' },
      width: 200,
    },
    {
      title: '状态',
      dataIndex: 'inspectionState',
      key: 'inspectionState',
      ellipsis: true,
      width: 100,
    },
    {
      title: '检查时间',
      dataIndex: 'inspectionTime',
      key: 'inspectionTime',
      ellipsis: true,
      width: 200,
      customRender: text => this.dayjs(text).format('YYYY-MM-DD'),
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 60,
    },
    {
      title: '',
      dataIndex: 'erCode',
      key: 'erCode',
      width: 50,
      scopedSlots: { customRender: 'erCode' },
    },
  ];
  threeProblemCompanyColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '检查编号',
      dataIndex: 'number',
      key: 'number',
      width: 200,
    },
    // {
    //   title: '项目',
    //   dataIndex: 'projectName',
    //   key: 'projectName',
    //   width: 200,
    // },
    {
      title: '检查人',
      dataIndex: 'fullName',
      key: 'fullName',
      ellipsis: true,
      width: 100,
    },

    {
      title: '单位名称',
      dataIndex: 'companyName',
      key: 'companyName',
      ellipsis: true,
      width: 100,
    },
    // {
    //   title: '检查类别',
    //   dataIndex: 'inspectionType',
    //   key: 'inspectionType',
    //   ellipsis: true,
    //   width: 200,
    // },
    {
      title: '检查照片',
      dataIndex: 'inspectionPictures',
      key: 'inspectionPictures',
      ellipsis: true,
      scopedSlots: { customRender: 'dImages' },
      width: 200,
    },
    {
      title: '状态',
      dataIndex: 'inspectionState',
      key: 'inspectionState',
      ellipsis: true,
      width: 100,
    },
    {
      title: '检查时间',
      dataIndex: 'inspectionTime',
      key: 'inspectionTime',
      ellipsis: true,
      width: 120,
      customRender: text => this.dayjs(text).format('YYYY-MM-DD'),
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 60,
    },
    {
      title: '',
      dataIndex: 'erCode',
      key: 'erCode',
      width: 50,
      scopedSlots: { customRender: 'erCode' },
    },
  ];
  threeProblemProjectColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 60,
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '检查编号',
      dataIndex: 'number',
      width: 200,
      key: 'number',
    },
    // {
    //   title: '项目',
    //   dataIndex: 'projectName',
    //   width: 200,
    //   key: 'projectName',
    // },
    {
      title: '检查人',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 100,
    },
    // {
    //   title: '检查类别',
    //   dataIndex: 'inspectionType',
    //   key: 'inspectionType',
    //   width: 200,
    // },
    // {
    //   title: '隐患类型',
    //   dataIndex: 'dangerType',
    //   key: 'dangerType',
    //   width: 200,
    // },
    {
      title: '检查部位',
      dataIndex: 'inspectionLocation',
      key: 'inspectionLocation',
      width: 200,
    },
    // {
    //   title: '检查内容',
    //   dataIndex: 'inspectionContent',
    //   key: 'inspectionContent',
    //   width: 200,
    // },
    {
      title: '检查照片',
      dataIndex: 'inspectionPictures',
      key: 'inspectionPictures',
      width: 200,
      scopedSlots: { customRender: 'dImages' },
    },
    {
      title: '状态',
      dataIndex: 'inspectionState',
      key: 'inspectionState',
      width: 80,
    },
    {
      title: '检查时间',
      dataIndex: 'inspectionTime',
      key: 'inspectionTime',
      width: 120,
      customRender: text => this.dayjs(text).format('YYYY-MM-DD'),
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 60,
      scopedSlots: { customRender: 'action' },
    },
    {
      title: '',
      dataIndex: 'erCode',
      key: 'erCode',
      width: 50,
      scopedSlots: { customRender: 'erCode' },
    },
  ];
  threeProblemWacthColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '检查编号',
      dataIndex: 'number',
      key: 'number',
      ellipsis: true,
      width: 200,
    },
    // {
    //   title: '项目',
    //   dataIndex: 'projectName',
    //   key: 'projectName',
    //   ellipsis: true,
    //   width: 200,
    // },
    {
      title: '检查人',
      dataIndex: 'fullName',
      key: 'fullName',
      ellipsis: true,
      width: 100,
    },
    {
      title: '单位名称',
      dataIndex: 'companyName',
      key: 'companyName',
      ellipsis: true,
      width: 200,
    },
    // {
    //   title: '所属科室',
    //   dataIndex: 'departmentName',
    //   key: 'departmentName',
    //   ellipsis: true,
    //   width: 130,
    // },
    // {
    //   title: '开单对象',
    //   dataIndex: 'billingObject',
    //   key: 'billingObject',
    //   ellipsis: true,
    //   width: 200,
    // },
    {
      title: '检查照片',
      dataIndex: 'inspectionPictures',
      key: 'inspectionPictures',
      ellipsis: true,
      scopedSlots: { customRender: 'dImages' },
      width: 200,
    },
    {
      title: '状态',
      dataIndex: 'inspectionState',
      key: 'inspectionState',
      ellipsis: true,
      width: 100,
    },
    {
      title: '检查时间',
      dataIndex: 'inspectionTime',
      key: 'inspectionTime',
      ellipsis: true,
      width: 200,
      customRender: text => this.dayjs(text).format('YYYY-MM-DD'),
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 60,
    },
    {
      title: '',
      dataIndex: 'erCode',
      key: 'erCode',
      width: 50,
      scopedSlots: { customRender: 'erCode' },
    },
  ];
  showThreeProblemDetailDialog(data) {
    // window.console.log(data, 'showThreeProblemDetailDialog');
    if (!data.name) return false;
    this.paramsData.state = data.name;
    this.paramsData.size = 10;
    let columns = this.threeProblemSafeColumns;
    this.keyword = '';
    this.paramsData.days = this.stateTypeDays;
    this.paramsData.current = 1;
    this.pagination = {
      current: 1,
      pageSize: 10,
    };
    let title = '其他检查数据';
    this.placeholder = '搜索检查单号';
    switch (this.stateType) {
      case 'SAFE_INSPECTION':
        title = '其他检查数据';
        this.dialogType = 'safe';
        columns = this.threeProblemSafeColumns;
        break;
      case 'ENTERPRISE_INSPECTION':
        columns = this.threeProblemCompanyColumns;
        title = '企业检查数据';
        this.dialogType = 'company';
        break;
      case 'PROJECT_INSPECTION':
        columns = this.threeProblemProjectColumns;
        title = '项目检查数据';
        this.dialogType = 'project';
        break;
      case 'SUPERVISE_INSPECTION':
        columns = this.threeProblemWacthColumns;
        title = '监督检查数据';
        this.dialogType = 'watch';
        break;

      default:
        break;
    }
    this.getInspectSafeRecordsDialogData();
    createModal(
      () => (
        <PeopleManageMentDialog
          columns={columns}
          showForm={false}
          showAction
          isLook
          total={this.total}
          data={this.threeDialogData}
          onChangePagination={this.changeThreeDialogPagination}
          dialogType={this.dialogType}
          title={`${title}单`}
          loading={this.loading}
          scopedSlots={{
            header: () => (
              <SpotForm
                placeholder={this.placeholder}
                onHandleSearch={debounce(this.handleSpotSearch, 1000)}
              />
            ),
          }}
        />
      ),
      {
        title: this.$t(title),
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }
  paramsData = {
    current: 1,
    days: '',
    projectId: this.projectId,
    size: 10,
    state: '',
    keyword: this.keyword,
  };
  changeThreeDialogPagination(data) {
    // window.console.log(data, 'changeThreeDialogPagination');
    this.paramsData.current = data.current;
    this.paramsData.size = data.pageSize;
    this.pagination = {
      current: data.current,
      pageSize: data.pageSize,
    };
    this.getInspectSafeRecordsDialogData();
  }
  threeDialogData = [];
  async getInspectSafeRecordsDialogData() {
    let res = null;
    this.loading = true;
    switch (this.stateType) {
      case 'SAFE_INSPECTION':
        res = await getInspectSafeRecords(this.paramsData);
        this.threeDialogData = res.records;
        this.total = res.total;
        break;
      case 'ENTERPRISE_INSPECTION':
        res = await getInspectEnterpriseRecords(this.paramsData);
        this.threeDialogData = res.records;
        this.total = res.total;
        break;
      case 'PROJECT_INSPECTION':
        res = await getInspectProjectRecords(this.paramsData);
        this.threeDialogData = res.records;
        this.total = res.total;
        break;
      case 'SUPERVISE_INSPECTION':
        res = await getInspectSuperviseRecords(this.paramsData);
        this.threeDialogData = res.records;
        this.total = res.total;
        break;
      default:
        this.threeDialogData = [];
        this.total = 0;
        break;
    }
    this.loading = false;
  }
  changeThreeTypesSwitchBtn(type) {
    this.stateType = type;
    this.paramsData.state = '';
    this.getInspectTypeStateData();
  }
}
</script>

<style lang="less" module>
.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.1rem;
  grid-template-rows: repeat(3, 32.6%);
  .card {
    grid-row: span 1;
    grid-column: span 1;
  }
  .bottom {
    display: grid;
    grid-column: span 3;
    grid-row: span 1;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.2rem;
    .card_2 {
      grid-column: span 1;
      grid-row: span 1;
      .actionBar {
        flex: 3;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .card_3 {
      grid-column: span 1;
      grid-row: span 1;
      .btnSwitch3 {
        flex: 3;
      }
    }
  }
}
</style>
